import React, { useState } from "react";
import Input from "../../components/Input";
import { Form } from "@unform/web";
import { notification, Modal } from "antd";
import { Button } from "@material-ui/core";
import icone from "../../assets/img/icone.png";
import { NavLink } from "react-router-dom";
import AuthService from "../../service/AuthService";
import Utils from "../../utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { WrapperMain, ContainerLogin } from "./styles";

export default function Recover() {
  const [loadings, setLoadings] = useState('false');

  const handleSubmit = async (data) => {
    if (!Utils.validateEmail(data.email_member)) {
      notification.warning({
        message: "Aviso!",
        description: "Insira um email válido!",
      });
      return false;
    }
    setLoadings('true');
    await AuthService.recover(data)
      .then((response) => {
        if (response.data.warning) {
          response.data.warning.forEach((item) => {
            notification.warning({
              message: "Aviso!",
              description: item,
            });
          });
          return false;
        }
        Modal.success({
          title:
            "Enviamos a recuperação de senha para o email. Confira na sua caixa de entrada ou spam!",
          icon: <ExclamationCircleOutlined />,
          okText: "OK",
          onOk: () => {
            // window.location.href = "/login";
          },
        });
      })
      .catch((error) => {
        notification.error({
          message: "Erro!",
          description: "Não foi possível efetuar o seu cadastro!",
        });
        console.log(error);
      });
    setLoadings('false');
  };

  return (
      <WrapperMain>
        <ContainerLogin>
          <div className={"big-container"}>
            <h1>Recuperar senha</h1>

            <Form className={"container-form"} onSubmit={handleSubmit}>
              <Input
                className={"input-item"}
                placeholder={"Insira o seu email cadastrado "}
                name="email_member"
                type="email"
              />
              <Button
                variant="contained"
                color="primary"
                id={"btn-default"}
                className={"text-btn btn-default"}
                type="submit"
                loading={loadings}
              >
                Enviar
              </Button>
              <NavLink
                className={"text-body"}
                to={"/login"}
                style={{ marginTop: 10, textAlign: "end", marginRight: 15 }}
              >
                {" "}
                Já tem cadastro? Clique aqui!
              </NavLink>
            </Form>
          </div>
          <div className={"small-container"}>
            <img
              src={icone}
              alt="ICONE- Oscar Quiroga, o Astrólogo e Humanista"
            />

            <h4>
              Sistema de cálculo e interpretações astrológicas de Oscar Quiroga,
              o Astrólogo e Humanista.
            </h4>
            <span>
              O sistema Quiroga permite que você insira nomes e dados de data
              nascimento de quantas pessoas quiser em seu próprio banco de dados
              pessoal, para que esses dados estejam convenientemente disponíveis
              quando você quiser fazer vários cálculos astrológicos, como:
            </span>
            <br />
          </div>
          <div className="services">
            <Button
              variant="outlined"
              color="primary"
              className={"btn-secundary"}
            >
              Cálculo astrológico
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={"btn-secundary"}
            >
              Relatório de seteraios
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={"btn-secundary"} 
            >
              Trânsitos e progressões
            </Button>
          </div>
        </ContainerLogin>
      </WrapperMain>
  );
}
