import React, {createContext, useState, useEffect, useContext} from "react";
import AuthService from "../service/AuthService";

const AuthContextData = {
  user: null,
  signIn: null,
  signOut: null,
};

const AuthContext = createContext(AuthContextData);
const USER = "@member_info";

function AuthProvider({children}) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    function loadStorageData() {
      const storagedUser = localStorage.getItem(USER);

      if (storagedUser) {
        setUser(JSON.parse(storagedUser));
      }
    }

    loadStorageData();
  }, []);

  async function signIn(data) {
    const response = await AuthService.login(data);

    if (response.data.warning) {
      return response;
    }

    setUser(response.data);
    localStorage.setItem(USER, JSON.stringify(response.data));

    return response;
  }

  async function signOut() {
    // const response = await AuthService.logout();
    // if (response) {
    localStorage.removeItem(USER);
    setUser(null);
    window.location.href = "/login";
    // }
  }

  return (
    <AuthContext.Provider value={{signed: !!user, user, signIn, signOut}}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export {AuthProvider, useAuth};
