import React, { useEffect } from "react";
import titulo from "../../assets/img/titulo.png";
import icone from "../../assets/img/icone.png";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import api from "../../service/api";
import { WrapperMain, ContainerLogin } from "./styles";
import TransitionAlerts from "../../components/Alert";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { goToLogin } from '../../config/cordination'
import { useHistory } from 'react-router';




export default function AuthenticateEmail() {

    const { email, token } = useParams();
    const history = useHistory()

    const authenticateEmail = () => {

        return (api.post("/authenticate-email", { email, token })
            .then((res) => {
                <TransitionAlerts>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    goToLogin(history);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        Seu e-mail foi verificado com sucesso!
                    </Alert>
                    </TransitionAlerts>
            })
            .catch((err) => {

            }))
    }


    useEffect(() => {
        if (!email || !token) {
            window.location.href = "/error";
        }
        authenticateEmail()

    }, []);

    return (
        <div>

            <WrapperMain>


                <ContainerLogin>

                    <div className={"img-container"}>
                        <img src={icone} alt="icone" />
                        <img src={titulo} alt="Oscar Quiroga, o Astrólogo e Humanista" />
                    </div>
                    <h4 className={"text-title"}>
                        Sistema de cálculo e interpretações astrológicas de Oscar Quiroga,
                        o Astrólogo e Humanista.
                    </h4>
                    <span>
                        O sistema Quiroga permite que você insira nomes e dados de data
                        nascimento de quantas pessoas quiser em seu próprio banco de dados
                        pessoal, para que esses dados estejam convenientemente disponíveis
                        quando você quiser fazer vários cálculos astrológicos, como:
                    </span>

                    <div className="services">
                        <Button
                            variant="outlined"
                            color="primary"
                            className={"btn-secundary"}
                        >
                            Cálculo astrológico
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={"btn-secundary"}
                        >
                            Relatório de seteraios
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={"btn-secundary"}
                        >
                            Trânsitos e progressões
                        </Button>
                    </div>

                </ContainerLogin>
            </WrapperMain>
        </div>
    );
}
