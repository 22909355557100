import React from "react";
import "./style.css";

export default function TermsAndConditions() {
  return (
    <div>
      <div className={"card-terms"}>
      <h3><strong>TERMOS E CONDIÇÕES DE SERVIÇO</strong></h3>
      <p align="justify">
        Leia atentamente estes Termos e Condições (“Termo”) antes de utilizar os serviços oferecidos pelo aplicativo
        “oastrologo.com” Essa Plataforma e/ou site (“Plataforma”) é operada e de titularidade exclusiva da
        “oastrologo.com” (“Titular”). Em toda a Plataforma, os termos “nós”, e “nosso” se referem a Titular.
      </p>
      <p align="justify">
        Ao acessar a Plataforma você (“Usuário”) concorda expressamente com todas e quaisquer disposições deste Termo,
        incluindo os termos e condições e políticas adicionais mencionados neste documento e/ou disponíveis por
        hyperlink. Este Termo aplica-se a todos os Usuários do site ou serviço, inclusive os Usuários que também
        contribuem com conteúdo, informação e outros materiais ou serviços hospedados na Plataforma. No caso de não
        haver a concordância pelo Usuário do presente Termo, aquele não poderá acessar e/ou utilizar quaisquer dos
        serviços e/ou conteúdos criados, disponibilizados e/ou fornecidos pela Plataforma.
      </p>
      <p align="justify">
        Quaisquer novos recursos e ferramentas e/ou mudança de legislação aplicável à matéria que implique em
        mudança
        substancial ao conteúdo da Plataforma, deverão ser adicionados ao presente Termo, devendo, quando desta
        ocorrência, ser submetido novamente a aceitação do Usuário, o qual tem a liberalidade de poder, sempre que
        for
        de seu interesse, ter acesso ao presente Termo.
      </p>
      <p align="justify">
        Ao visitar e/ou efetuar cadastro por meio de nossa Plataforma, o Usuário utilizará os “Serviços”
        disponibilizados, nos termos e Condições Previstos neste documento e em documentos com direitos e deveres
        anexos
        previstos no site e/ou na Plataforma.
      </p>
      <p align="justify">
        Os Serviços permitem que o Usuário tenha um atendimento astrológico especializado gerado através de cálculos
        e
        interpretações com base nos dados pessoais que são por este fornecidos espontaneamente pelo Usuário e
        utilizados
        somente para os fins propostos na prestação dos Serviços disciplinados no Termo.
      </p>
      <h3><strong> SEÇÃO 1 - TERMOS DE USO DA PLATAFORMA </strong></h3>
      <p align="justify">
        Ao concordar com o presente Termo, o Usuário declara que possui idade igual ou superior aos 18 (dezoito)
        anos
        de
        idade completos e sem qualquer incapacidade para uso e gozo de direitos, sendo, portanto, maior e capaz de
        assumir deveres e obrigações perante toda a sociedade civil, de sorte que a utilização desta Plataforma se
        dá
        de
        maneira exclusiva ao Usuário, bem como a responsabilidade pela salvaguarda do nome de Usuário e senha de
        acesso,
        não devendo, sob hipótese alguma, a Titular ser responsabilizada pelo uso indevido da Plataforma pelo
        Usuário
        ou
        por terceiros com autorização ou não do Usuário. O Usuário não deve, sob hipótese alguma, utilizar a
        Plataforma
        para qualquer fim ilegal ou não autorizado, bem como não poderá violar quaisquer leis em sua jurisdição
        (incluindo, mas não limitado, a leis de direitos autorais).
      </p>
      <h3><strong> SEÇÃO 2 - CONDIÇÕES GERAIS </strong></h3>
      <p align="justify">
        A Titular se reserva no direito de recusar o cadastramento de qualquer Usuário por qualquer motivo e a
        qualquer
        momento.
        O Usuário declara e concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer
        parte
        da
        Plataforma, sendo qualquer conteúdo gerado utilizando como base a Plataforma, de titularidade exclusiva da
        Titular.
      </p>
      <h3><strong> SEÇÃO 3 - PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES </strong></h3>
      <p align="justify">
        A Titular não é, bem como nunca será, responsável por quaisquer informações disponibilizadas pelo Usuário.
      </p>
      <p align="justify">
        Na Plataforma poderá conter certas informações históricas. As informações históricas podem não ser atuais e
        são
        fornecidas apenas para sua referência. Dessa forma, a Titular se reserva no direito de modificar a qualquer
        momento o conteúdo disponibilizado na Plataforma, sendo responsabilidade exclusiva do Usuário o
        monitoramento
        do
        site para verificar as alterações realizadas pela Titular.
      </p>
      <h3><strong> SEÇÃO 4 - MODIFICAÇÕES DO SERVIÇO E PREÇOS </strong></h3>
      <p align="justify">
        Caso a Titular deseje atualizar os preços dos produtos eventualmente disponibilizados na Plataforma, esta
        poderá
        fazer sem qualquer necessidade de notificação e/ou aceitação prévia do Usuário, sem que este, no entanto
        prejudique o serviço ora contratado. Neste caso, o Usuário deverá ser notificado do reajuste e deverá ter a
        opção de cancelamento dos Serviços.
      </p>
      <h3><strong> SEÇÃO 5 - PRODUTOS OU SERVIÇOS </strong></h3>
        <p align="justify">
          Certos produtos e/ou serviços podem estar disponíveis exclusivamente on line, através do site e/ou
          exclusivamente através da Plataforma mobile. Tais produtos e/ou serviços podem ter quantidades limitadas e
          são
          sujeitos a devolução ou troca, de acordo com o estabelecido na legislação aplicável.
        </p>
        <p align="justify">
          A Titular se reserva no direito de limitar as vendas de produtos ou de serviços para qualquer pessoa,
          região
          geográfica ou jurisdição, ficando a seu exclusivo critério referido decisão, sendo que, toda e qualquer
          descrição de produtos ou preços de produtos estão sujeitos à alteração em qualquer momento, sem
          necessidade de
          notificação prévia ao Usuário. A Titular se reserva no direito de descontinuar qualquer produto e/ou
          serviço
          disponibilizado na Plataforma a qualquer momento.
        </p>
        <p align="justify">
          O Usuário desde já atesta estar ciente e de acordo que astrologia, tarot e demais ciências esotéricas são
          ciências interpretativas, de modo que o Titular não poderá ser responsabilizado, de nenhuma forma, sequer
          em
          caráter subsidiário, caso as interpretações emitidas nos textos disponibilizados ao Usuário e/ou no site
          “oastrologo.com” não se concretizem, sendo certo que os serviços prestados pelo Titular não constituem
          conselhos
          legais, psicológicos, médicos, empresariais, financeiros ou de qualquer outra espécie e, tampouco,
          configuram
          indicação, diagnóstico ou tratamento de qualquer tipo, seja ele médico, mental, espiritual ou de qualquer
          outra
          espécie.
        </p>

        <h3><strong>SEÇÃO 6 - PRECISÃO DE INFORMAÇÕES DE FATURAMENTO E CONTA</strong></h3>
        <p align="justify">
          A Titular se reserva no direito de recusar qualquer solicitação do Usuário, podendo, a seu exclusivo
          critério,
          limitar ou cancelar qualquer produto e/ou serviços que o Usuário adquira por meio da Plataforma, no caso
          de
          qualquer incongruência de informações no seu cadastro. Caso a Titular faça alterações, limitações e/ou
          cancelamentos, informará previamente ao Usuário por meio do endereço de e-mail informado por este para
          seja
          sanado o problema em data limite estipulada pela Titular.
        </p>
        <p align="justify">
          O Usuário concorda em fornecer as informações e/ou dados necessários para a conclusão de compras feitas
          via
          Plataforma. O Usuário declara que concorda em manter seu endereço de e-mail e informações cadastrais
          atualizadas
        </p>

        <h3><strong> SEÇÃO 7 - FERRAMENTAS OPCIONAIS </strong></h3>
        <p align="justify">
          A Titular poderá fornecer ao Usuário acesso a ferramentas de terceiros, da qual a Titular não monitora,
          bem
          como
          não tem qualquer controle sobre a qualidade das informações prestadas ou a sua quantidade, não devendo a
          Titular
          ser responsabilizada por qualquer situação decorrente desta utilização.
        </p>
        <p align="justify">
          O Usuário reconhece e concorda que referido acesso a tais ferramentas “como elas são” e “conforme a
          disponibilidade”, não possui quaisquer garantias, representações ou condições de qualquer tipo e sem
          qualquer
          endosso. Não nos responsabilizamos de forma alguma pelo seu uso de ferramentas opcionais de terceiros.
          Qualquer
          uso de ferramentas opcionais oferecidas através da Plataforma é inteiramente por conta e risco do Usuário.
        </p>
        <h3><strong> SEÇÃO 8 - LINKS DE TERCEIROS</strong></h3>
        <p align="justify">
          Certos produtos, conteúdos e/ou serviços disponibilizados pela Titular, por meio da Plataforma, podem
          incluir
          materiais de terceiros. Os links de terceiros na Plataforma podem direcionar o Usuário para outros sites
          e/ou
          plataformas que não são afiliados ao Titular.
        </p>
        <p align="justify">
          A Titular não se responsabiliza por examinar ou avaliar o conteúdo ou precisão de site e/ou material de
          terceiros. Não garantimos e nem temos obrigação ou responsabilidade por quaisquer materiais ou sites de
          terceiros, ou por quaisquer outros materiais, produtos ou serviços de terceiros.
        </p>
        <p align="justify">
          A Titular não se responsabiliza por quaisquer danos ou prejuízos relacionados com a compra ou uso de
          mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com quaisquer
          sites
          de terceiros.
        </p>
        <p align="justify">
          É de responsabilidade exclusiva do Usuário, revisar com cuidado as políticas e práticas de terceiros e
          certificar-se que as entende antes de efetuar qualquer transação. As queixas, reclamações, preocupações ou
          questões relativas a produtos de terceiros devem ser direcionadas ao terceiro e nunca à Titular e/ou à
          Plataforma.
        </p>

        <h3><strong> SEÇÃO 9 - ERROS, IMPRECISÕES E OMISSÕES</strong></h3>
        <p align="justify">
          Ocasionalmente, a Titular declara que pode haver informações na Plataforma que contenha erros
          tipográficos,
          imprecisões ou omissões que possam relacionar-se a descrições de produtos e/ou serviços. A Titular se
          reserva
          no
          direito de corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou atualizar informações caso
          qualquer informação no Serviço ou em qualquer site relacionado que seja imprecisa, a qualquer momento e
          sem
          aviso prévio. A Titular fica isenta de responsabilidade de to do e qualquer dano, direto ou indireto,
          provenientes dos possíveis erros que venham a acontecer.
        </p>
        <h3><strong> SEÇÃO 10 - USOS PROIBIDOS</strong></h3>
        <p align="justify">
          Além de outras proibições, conforme estabelecido no presente Termo, o Usuário declara reconhecer que está
          proibido de usar a Plataforma para: (a) fins ilícitos; (b) solicitar outras pessoas a realizar ou
          participar
          de
          quaisquer atos ilícitos; (c) violar quaisquer regulamentos internacionais, provinciais, estaduais ou
          federais,
          regras, leis ou regulamentos locais; (d) infringir ou violar os direitos de propriedade intelectual da
          Titular
          ou os direitos de propriedade intelectual de terceiros; (e) para assediar, abusar, insultar, danificar,
          difamar,
          caluniar, depreciar, intimidar ou discriminar com base em gênero, orientação sexual, religião, etnia,
          raça,
          idade, nacionalidade ou deficiência; (f) apresentar informações falsas ou enganosas; (g) fazer o envio ou
          transmitir vírus ou qualquer outro tipo de código malicioso que será ou poderá ser utilizado para afetar a
          funcionalidade ou operação da Plataforma ou de qualquer site relacionado, outros sites, ou da Internet;
          (h)
          coletar ou rastrear as informações pessoais de outras pessoas; (i) para enviar spam, phishing, pharm,
          pretext,
          spider, crawl, ou scrape; (j) para fins obscenos ou imorais; (k) para interferir ou contornar os recursos
          de
          segurança da Plataforma ou de qualquer site relacionado, outros sites, ou da Internet; ou (l) burlar,
          desabilitar, fraudar ou interferir com qualquer parte do Serviço (ou tentar realizar essas ações),
          incluindo
          recursos relacionados à segurança ou que (a) impeçam ou restrinjam a cópia ou outro uso do Conteúdo ou (m)
          limitem o uso do Serviço ou Conteúdo; (n) para realizar captura de dados pessoais de outros Usuários ou
          mesmo
          de
          terceiros que, por ventura, estejam ou forem disponibilizados pela Plataforma.
        </p>
        <p align="justify">
          A Titular se reserva no direito de banir o Usuário da Plataforma ou de qualquer site relacionado por
          violar
          qualquer um dos usos proibidos descritos acima, além de tomada de medidas jurídicas que entenda sejam
          válidas
          à
          salvaguarda de seus direitos e de terceiros.
        </p>
        <h3><strong> SEÇÃO 11 - ISENÇÃO DE RESPONSABILIDADE</strong></h3>
        <p align="justify">
          O Usuário concorda que, periodicamente, a Titular poderá remover a Plataforma por períodos indefinidos de
          tempo,
          a qualquer momento, sem necessidade de notificação prévia ao Usuário.
        </p>
        <p align="justify">
          O Usuário concorda que a incapacidade de usar a Plataforma é por sua conta e risco.
        </p>
        <p align="justify">
          Em nenhuma circunstância a Titular, seus diretores, oficiais, funcionários, afiliados, agentes,
          contratantes,
          estagiários, fornecedores, prestadores de serviços ou licenciadores serão responsáveis por qualquer
          prejuízo,
          perda, reclamação ou danos diretos, indiretos, incidentais, punitivos, especiais ou consequentes de
          qualquer
          tipo, incluindo, sem limitação, lucros cessantes, perda de receita, poupanças perdidas, perda de dados,
          custos
          de reposição, ou quaisquer danos semelhantes, seja com base em contrato, ato ilícito (incluindo
          negligência),
          responsabilidade objetiva ou de outra forma, decorrentes do seu uso de qualquer um dos serviços ou
          quaisquer
          produtos adquiridos usando o serviço, ou para qualquer outra reclamação relacionada de alguma forma ao seu
          uso
          do serviço ou qualquer produto, incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer
          conteúdo,
          ou qualquer perda ou dano de qualquer tipo como resultado do uso do serviço ou qualquer conteúdo (ou
          produto)
          publicado, transmitido ou de outra forma disponível através da Plataforma, mesmo se alertado de tal
          possibilidade.
        </p>
        <h3><strong>SEÇÃO 12 – INDENIZAÇÃO</strong></h3>
        <p align="justify">
          O Usuário concorda em indenizar, defender e isentar a Titular e suas eventuais subsidiárias, afiliadas,
          parceiras, funcionárias, diretores, agentes, contratados, licenciantes, prestadores de serviços,
          subcontratados,
          fornecedores, estagiários e funcionários de qualquer reclamação ou demanda, por quaisquer violações deste
          Termo
          ou aos documentos que o incorporam por referência, ou à violação de qualquer lei ou os direitos de um
          terceiro.
        </p>
        <h3><strong> SEÇÃO 13 – INDEPENDÊNCIA</strong></h3>
        <p align="justify">
          No caso de qualquer disposição deste Termo ser considerada ilegal, nula ou ineficaz, tal disposição deve,
          contudo, ser aplicável até ao limite máximo permitido pela lei aplicável, e a porção inexequível será
          considerada separada deste Termo. Tal determinação não prejudica a validade e aplicabilidade de quaisquer
          outras
          disposições restantes.
        </p>
        <h3><strong> SEÇÃO 14 – DA PROPRIEDADE INTELECTUAL, DIREITOS AUTORAIS E MARCAS </strong></h3>
        <p align="justify">
          To do o material disponibilizado na Plataforma (a sua apresentação e “layout”, marcas, logotipos, produtos,
          sistemas, denominações de serviços e outros materiais), incluindo programas, bases de dados, imagens,
          arquivos
          ou materiais de qualquer outra espécie e que têm contratualmente autorizadas as suas veiculações neste
          portal,
          é
          protegido pela legislação de Propriedade Intelectual, sendo de propriedade da Titular, suas afiliadas,
          fornecedores ou clientes. A reprodução, distribuição e transmissão de tais materiais não são permitidas
          sem o
          expresso consentimento por escrito da Titular, especialmente para fim comercial ou econômico.
        </p>
        <p align="justify">
          O uso indevido de materiais protegidos por propriedade intelectual (direito autoral, marcas comerciais,
          patentes
          etc.) apresentados Plataforma será caracterizado como infração da legislação pertinente, sujeitando o
          infrator
          às ações judiciais cabíveis e dando ensejo à respectiva indenização aos prejudicados, seja à Titular, seja
          a
          terceiros, sem prejuízo de perdas e danos e honorários advocatícios.
        </p>
        <p align="justify">
          É vedada a reprodução, duplicação, cópia, venda ou revenda e/ou exploração de qualquer parte do Serviço,
          uso
          do
          Serviço, acesso ao Serviço, ou qualquer contato no site através do qual o serviço é fornecido, salvo
          autorização
          expressa por escrito da Titular.
        </p>
        <h3><strong>SEÇÃO 15 - DOS RISCOS TECNOLÓGICOS</strong></h3>
        <p align="justify">
          Todos os riscos derivados da utilização da Plataforma são do Usuário. Se o seu uso resultar na necessidade
          de
          serviços ou reposição de propriedade ou informação do Usuário, a Titular não será responsável por tais
          custos.
        </p>
        <p align="justify">
          Todas as informações sobre quaisquer eventos publicados na Plataforma, podem conter erros tipográficos ou
          imprecisões. Alterações e ajustes das informações são realizados periodicamente e sem qualquer aviso. Em
          nenhum
          caso a Titular será responsabilizada por qualquer dano direto, indireto, incidental, especial ou como
          consequência de quaisquer fatos resultantes do uso do portal ou da inabilidade de uso deste, ou ainda por
          quaisquer informações, produtos ou serviços obtidos através dele ou em decorrência do seu uso.
        </p>
        <h3><strong>SEÇÃO 16 – RESCISÃO</strong></h3>

        <p align="justify">
          As obrigações e responsabilidades das Partes incorridas antes da data de rescisão devem continuar após a
          rescisão deste acordo para todos os efeitos.
        </p>
        <p align="justify">
          Este Termo permanecerá em vigor, contudo, o Usuário poderá rescindir a presente assunção, notificando a
          Titular
          da sua intenção de não utilizar a Plataforma.
        </p>
        <p align="justify">
          O Usuário terá o direito de cancelar a compra do produto em até 7 dias sem nenhum tipo de ônus. Passado
          este
          prazo, caso ocorra a desistência, o Usuário se compromete a pagar o valor do produto.
        </p>
        <h3><strong> SEÇÃO 17 - ACORDO INTEGRAL </strong></h3>
        <p align="justify">
          No caso de a Titular não exercer ou executar qualquer direito ou disposição deste Termo, referido ato não
          constituirá uma renúncia de tal direito ou disposição.
        </p>
        <p align="justify">
          Este termo rege a totalidade do uso da Plataforma, substituindo quaisquer acordos anteriores ou
          contemporâneos,
          comunicações e propostas, sejam verbais ou escritos, entre as partes.
        </p>
        <h3><strong> SEÇÃO 18 - LEGISLAÇÃO APLICÁVEL </strong></h3>
        <p align="justify">
          Esse Termo deverá ser interpretado por meio de aplicação da legislação brasileira.
        </p>

        <h3><strong> SEÇÃO 19 - ALTERAÇÕES DOS TERMOS DE SERVIÇO </strong></h3>
        <p align="justify">
          A Titular se reserva no direito de atualizar, modificar ou substituir qualquer parte deste Termo, sendo de
          responsabilidade do Usuário verificar a Plataforma periodicamente.
        </p>
        <p align="justify">
          O uso contínuo ou acesso a Plataforma após a publicação de quaisquer alterações a este Termo constituirá
          aceitação tácita do Usuário aos novos direitos e obrigações constantes na Plataforma.
        </p>
        <h3><strong> SEÇÃO 20 - POLÍTICA DE CYBERSECURITY</strong></h3>
        <p align="justify">
          A Titular e o Usuário, no cumprimento da legislação aplicável e restrições ao uso e dados, se obrigam a
          observar
          a legislação aplicável em relação a proteção de dados pessoais, incluindo mas não se limitando ao
          Regulamento
          Geral sobre a Proteção de Dados 2016/679 (“GPDR”) e Lei Geral de Proteção de Dados Pessoais brasileira,
          Lei
          Federal nº 13.709/18 (“LGPD”).
        </p>
        <p align="justify">
          Os dados pessoais fornecidos pelo Usuário à Titular, ou de terceiros fornecidos pelo Usuário, ou que de
          outra
          maneira se tornem acessíveis à Titular, somente poderão ser utilizadas para a execução destes Termos e
          Condições.
        </p>
        <p align="justify">
          A Titular declara e garante que: (i) somente tratará dados para propósitos legítimos, específicos,
          explícitos
          e
          informados ao titular, sem possibilidade de tratamento posterior de forma incompatível com essas
          finalidades;
          (ii) garantirá a compatibilidade do tratamento com as finalidades informadas ao Usuário, de acordo com o
          contexto do tratamento; (iii) limitará o tratamento ao mínimo necessário para a realização de suas
          finalidades,
          com abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do
          tratamento
          de
          dados; (iv) garantirá ao Usuário a consulta facilitada e gratuita sobre a forma e a duração do tratamento,
          bem
          como sobre a integridade de seus dados pessoais; (v) garantirá ao Usuário a exatidão, a clareza, a
          relevância
          e
          a atualização dos dados, de acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;
          (vi)
          garantirá ao Usuário as informações claras, precisas e facilmente acessíveis sobre a realização do
          tratamento
          e
          os respectivos agentes de tratamento, observados os segredos comercial e industrial; (vii) utilizará
          medidas
          técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações
          acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão; (viii) adotará as medidas
          para
          prevenir a ocorrência de danos em virtude do tratamento de dados pessoais; (ix) em hipótese alguma
          realizará
          o
          tratamento para fins discriminatórios ilícitos ou abusivos; (x) demonstrará a adoção de medidas eficazes e
          capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive,
          da
          eficácia dessas medidas.
        </p>
        <p align="justify">
          A Titular (i) notificará o Usuário prontamente sobre reclamações cujo conteúdo indique violação de dados
          de
          origem sensível, de titulares de dados, recusas ou pedidos de autoridade supervisora, quebra de
          disposições
          dessa cláusula ou da lei aplicável, ou em caso de suspeita razoável de tais reclamações, pedidos ou
          violações;
          e
          (ii) informará prontamente ao Usuário: a) descrição da natureza dos dados pessoais afetados; b) as
          informações
          sobre os titulares envolvidos cujos dados pessoais podem ter se tornado objeto do incidente; c) a
          indicação
          das
          medidas técnicas e de segurança utilizadas para a proteção de dados, observados os segredos comercial e
          industrial; d) os riscos e possíveis consequências relacionados ao incidente; e) os motivos da demora, no
          caso
          de a comunicação não ter sido imediata; e f) descrição das medidas e ações corretivas tomadas ou propostas
          para
          tratar a violação de dados, incluindo, medidas para reverter ou mitigar os efeitos do prejuízo.
        </p>
        <p align="justify">
          A Titular declara e garante ter ciência que toda pessoa natural tem assegurada a titularidade de seus
          próprios
          dados pessoais, individuais e intransferíveis, e garantidos os direitos fundamentais de liberdade, de
          intimidade
          e de privacidade, nos termos do artigo 17 da Lei Geral de Proteção de Dados Pessoais Brasileira, Lei nº
          13.709/18 (“LGPD”).
        </p>
        <p align="justify">
          A Titular declara e garante ter ciência de que o tratamento de dados não poderá acarretar prejuízos à
          imagem,
          à
          segurança ou à integridade do titular e declara ter ciência de que a defesa dos interesses e direitos do
          titular
          poderá ser exercida em juízo, individualmente ou coletivamente, conforme disposto nos artigo 21 e 22 da
          Lei
          Geral de Proteção de Dados Pessoais Brasileira, Lei nº 13.709/18 (“LGPD”).
        </p>
        <p align="justify">
          A Titular informa que se utiliza de cookies para aprender como o Usuário interage com o conteúdo para
          melhorar
          sua experiência ao visitar a Plataforma. Por exemplo, alguns cookies lembram o seu idioma ou preferências
          para
          que você não tenha que efetuar estas escolhas repetidamente sempre que visitar um dos nossos websites.
        </p>
        <p align="justify">
          Além disso, os cookies permitem que sejam oferecidos ao Usuários um conteúdo específico, tais como vídeos
          no(s)
          nosso(s) website(s).
        </p>
        <p align="justify">
          A Titular pode ainda empregar aquilo que foi identificado sobre o comportamento do Usuário no(s) nosso(s)
          website(s) para oferecer anúncios direcionados em website(s) de terceiros em um esforço para
          “reapresentar”
          nossos produtos e serviços para você.
        </p>
        <p align="justify">
          A Titular coleta e utiliza informação contida nos anúncios. A informação contida nos anúncios da Titular
          e/ou
          de
          terceiros inclui o seu endereço IP (Internet Protocol), o seu ISP (Internet Service Provider), o browser
          que
          utilizou ao visitar o nosso website (como o Google Chrome ou o Firefox), o tempo da sua visita e que
          páginas
          visitou dentro do nosso website, de modo que a Titular busca com tais informações entender o perfil do
          Usuário.
        </p>
        <h3><strong>SEÇÃO 21 - INFORMAÇÕES DE CONTATO</strong></h3>
        <p align="justify">
          Qualquer questionamento relacionado a este Termo deverá ser direcionado ao seguinte e-mail:
          <a href="mailto:luciana@osunicos.com.br"> luciana@osunicos.com.br </a>
        </p>
      </div>
    </div>
);
}