import NewHeader from "../../components/NewHeader"
import NewFooter from "../../components/NewFooter"
import { Main } from "./styles"

const Config = () => {
    return (
        <div>
            <NewHeader />
            <Main>
                <h1>Configurações do usuário</h1>
                
            </Main>
            <NewFooter />
        </div>)
}

export default Config