import styled from "styled-components";

export const WrapperData= styled.div`
display: grid;
grid-template-columns: 4fr 1fr;
@media (max-width: 800px) {
display: grid;
grid-template-columns: 7fr 1fr;
}
`
export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
grid-column: 2;
gap:100px;

button{
    width: 145px;
    margin-bottom:20px;
}
.free{
:hover{
    background-color: green;
    color:#FFFFFF;
    box-shadow: 1px 1px 5px 1px green;
    transform:scale(1.1);
}}
.not-free{
    :hover{
   color: #DCAB29;
    transform:scale(1.1);
}
}
@media (max-width: 800px) {
        display: grid;
        justify-content: center;
button{
font-size: 0.5em;
width: 50%;
height: 5vh;

}
    }
`
export const WrapperDetail = styled.div`
display: flex;
text-align: center;
width: 90%;
flex-direction: column;
background-color:rgba(167,162,169);
border-radius: 5px;
padding: 20px;

label{
    background-color: #FFFFFF;
    text-align: center;
    margin-bottom:10px;

}
@media (max-width: 800px) {
        display: grid;
        justify-content: center;
        width: 90%;  
    }
    
`