import styled from "styled-components";
import background from "../../assets/img/background.png"

export const WrapperMain = styled.main`
    display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 80vh;
  background: url(${background});
  box-shadow: inset 0 0 0 1300px rgba(255, 255, 255, 0.5);
  background-size: 100vw auto;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: "Montserrat" !important;

`
export const WrapperNewData = styled.div`
margin-right: 10%;
margin-top: -10%;
@media (max-width: 800px) {
    margin:auto;
}
form{
display:grid ;
padding: 20px;
border-radius: 5px;
grid-template-columns: 1fr 1fr;
justify-items: center;
width: 50vw;
height: 40vh;
justify-content: flex-end;
background-color: rgba(167,162,169,0.5);
@media (max-width: 800px) {
    display:grid ;
    grid-template-columns: 2fr 1fr;    
    width: 90vw;
    height: auto;
button{
font-size: 0.5em;
width: 30%;
height: 5vh;
}
}

}

`
export const ContainerInputs = styled.div`
display: grid;
align-items: center;
text-align: center;
grid-column: 1;
width: 100%;
h3{
    color:#77051B;
}
input{
    background-color: #FFFFFF;
}
@media (max-width: 800px) {

        input{
            height: 2vh;
        }
        h3{
            font-size: 0.7em;
        }
}
`
export const ContainerMap = styled.div`
display: grid;
grid-row: 5fr 1fr;
align-items: flex-end;
grid-column: 2;
button{
    margin:20px;
}
`