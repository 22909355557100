
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import { Button } from '@material-ui/core';
import { ButtonContainer, WrapperData, WrapperDetail } from './styles';
import PaymentService from "../../service/PaymentService";

const storagedUser = JSON.parse(localStorage.getItem("@member_info"));

function calculateSevenRays(ID) {
    console.log(ID)
    const customId = {
        birth_id: ID,
        member_email: storagedUser.email,
    };
    PaymentService.get(customId)
        .then((success) => {
            if (
                Object.keys(success.data).length !== 0 &&
                success.data.status === "COMPLETED"
            ) {
                window.open("/rays/" + ID, "_blank");
                return false;
            }
            window.open("/payment/" + ID, "", "width=500, height=500");
        })
        .catch((error) => {
            console.log(error);
        });
}

function calculateNatal(id) {
    window.open("/natal/" + id, "_blank");
}

function calculateTransitsAndProgressions(id) {
    window.open("/transits/" + id, "_blank");
}



const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        background: '#77051B',
        width: '90vw',
        maxWidth: '600px',
        height: '40vh',
        padding: '20px',
        display: 'flex',
        gridColumn: '2',
        justifyContent: 'rigth',


    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const DetailsData = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <section>

            <IconButton onClick={handleClick} >
                <MenuIcon />
            </IconButton>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                <WrapperData>

                    <WrapperDetail>
                        <h1> Resumo </h1>
                        <label>dados da API</label>
                        <label>dados da API</label>
                        <label>dados da API</label>
                        <label>dados da API</label>
                        <label>dados da API</label>
                        <label>dados da API</label>
                        <label>dados da API</label>
                        <label>dados da API</label>

                        <Button
                            variant="contained"
                            color="primary"
                        >Editar</Button>
                    </WrapperDetail>


                    <ButtonContainer>
                        <div>
                            <Button onClick={() => {
                                calculateNatal();
                            }}
                                variant="contained"
                                color="secondary"
                                className="free"> ASTROLÓGICO</Button>
                            <Button onClick={() => {
                                calculateSevenRays();
                            }}
                                variant="contained"
                                color="secondary"
                                className="not-free">SETE RAIOS</Button>
                            <Button onClick={() => {
                                calculateTransitsAndProgressions();
                            }}
                                variant="contained"
                                color="secondary"
                                className="not-free">TRÂNSITOS E PROGRESSÕES</Button>
                        </div>
                        <Button onClick={handleClose}
                            variant="contained"
                            color="default">Fechar</Button>
                    </ButtonContainer>
                </WrapperData>

            </StyledMenu>

        </section>
    );
}

export default DetailsData