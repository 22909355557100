import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Recover from "../pages/Login/Recover";
import Template from "../pages/Template";
import Error from "../pages/Error/NotFound";
import { useAuth } from "../contexts/auth";
import BirthInfo from "../pages/BirthInfo";
import Payment from "../pages/Payment";
import Natal from "../pages/Calculations/natal";
import Rays from "../pages/Calculations/rays";
import Transits from "../pages/Calculations/transits";
import Welcome from "../pages/Welcome";
import AuthenticateEmail from "../pages/Login/AuthenticateEmail";
import NewHome from "../pages/NewHome"
import NewProfile from "../pages/NewProfile";
import NewData from "../pages/NewData";
import Config from "../pages/Config";
import LoginPage from "../pages/Login/Login";
import SignUp from "../pages/Login/SignUp";
import Termos from "../pages/Terms";

export default function Routes() {
  const { signed } = useAuth();

  if (!signed) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/home">
            <NewHome />
          </Route>
          <Route path="/recover">
            <Recover />
          </Route>
          <Route path="/new-password/:email/:token">
            <Recover />
          </Route>
          <Route path="/authenticate-email/:email/:token">
            <AuthenticateEmail />
          </Route>
          <Route path="/termos">
            <Termos />
          </Route>
          <Route exact path="*">
            <LoginPage />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <NewHome />
        </Route>
        <Route path="/birthinfo">
          <BirthInfo />
        </Route>
        <Route path="/natal/:id">
          <Natal />
        </Route>
        <Route path="/rays/:id">
          <Rays />
        </Route>
        <Route path="/transits/:id">
          <Transits />
        </Route>
        {/* <Route path="/welcome">
          <Welcome />
        </Route> */}
        <Route path="/template">
          <Template />
        </Route>
        <Route path="/config">
          <Config />
        </Route>
        <Route path="/profile">
          <NewProfile />
        </Route>
        <Route path="/newdata">
          <NewData/>
        </Route>
        <Route path="/payment/:id">
          <Payment />
        </Route>
        <Route path="/error">
          <Error />
        </Route>
        <Route path="*">
          <NewHome />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
