import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#77051B',
        },
        secondary: {
            main: '#A7A2A9',
            contrastText: '#77051B'
        },
        default: {
            main: '#E2DFE2',
        }
    },
    text: {
        primary: '#77051B',
        secondary: '#A7A2A9'
    },
    typography: {
        fontFamily: '"Montserrat","Questrial"',
        body1: {
            fontFamily: "Montserrat"
        },
        h1: {
            fontFamily: "Questrial",
            fontWeight: 900,
            fontSize: "6rem",
            fontColor: '#77051B',
            textTransform: "capitalize"
        },
        button: {
            fontFamily: "Questrial",
            fontWeight: 700,
            fontColor: '#77051B',
            textTransform: "uppercase"
        }
    },
    overrides: {
        MuiCssBaseline: {
          '@global': {
            html: {
                fontFamily: '"Montserrat","Questrial"',
                body: {
                    fontFamily: "Montserrat"
                },
                h1: {
                    fontFamily: "Questrial",
                    fontWeight: 900,
                    fontSize: "6rem",
                    fontColor: '#77051B',
                    textTransform: "capitalize"
                },
                button: {
                    fontFamily: "Questrial",
                    fontWeight: 700,
                    fontColor: '#77051B',
                    textTransform: "uppercase"
                }
            },
          },
        },
      },
});


export default theme;
