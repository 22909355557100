export default class Utils {
  static validateEmail(email) {
    //http://jsfiddle.net/ghvj4gy9/
    //http://www.ex-parrot.com/~pdw/Mail-RFC822-Address.html

    var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return EMAIL_REGEX.test(String(email).toLowerCase());
  }

  static ToDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return `${degrees}º ${minutes}' ${seconds}''`;
  }

  static ConvertDMSLat(lat) {
    var latitude = this.ToDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    return latitude + " " + latitudeCardinal;
  }

  static ConvertDMSLng(lng) {
    var longitude = this.ToDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return longitude + " " + longitudeCardinal;
  }

  static ParseDMS(input) {
    var parts = input.split(/[^\d\w]+/);
    var lat = this.ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
    var lng = this.ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);

    return { lat, lng };
  }

  static ConvertDMSToDD(degrees, minutes, seconds, direction) {
    var dd = degrees + minutes / 60 + seconds / (60 * 60);

    if (direction === "S" || direction === "W") {
      dd = dd * -1;
    } // Don't do anything for N or E
    return dd;
  }
}
