import Service from "./Service";
import api from "./api";

const USER = "@member_info";

export default class PaymentService extends Service {
  static service = "/payment";

  static async getAll() {
    const storagedUser = JSON.parse(localStorage.getItem(USER));
    return await api.get(`${this.service}?username=${storagedUser.username}`);
  }
  static async get(customId) {
    return await api.get(`${this.service}/get?member_email=${customId.member_email}&birth_id=${customId.birth_id}`);
  }

  static async create(data) {
    return await api.post(`${this.service}`, data);
  }

  static async update(id, data) {
    return await api.put(`${this.service}${id}`, data);
  }

  static async delete(id) {
    return await api.delete(`${this.service}${id}`);
  }

  static async save(data) {
  console.log(data);
  // return false;
    const customId = data.purchase_units[0].custom_id.split("#");

      const tablePayment = {
        id_payment: data.id,
        payer_id: data.payer.payer_id,
        member_email: customId[0],
        birth_id: +customId[1],
        status: data.status,
        update_time: data.update_time,
        links: data.links[0].href
      };

    return await api.post(`${this.service}`, tablePayment);

  }

}
