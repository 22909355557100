import styled from "styled-components";
import bg from "../../assets/img/bg.png"

export const WrapperMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 80vh;
  background: url(${bg});
  box-shadow: inset 0 0 0 1300px rgba(255, 255, 255, 0.5);
  background-size: 100vw auto;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: "Montserrat" !important;

`
export const ContainerPayPall = styled.div`
display: grid;
width: 50vw;
`