import { Foot } from "./styles"
import facebook from "../../assets/img/facebook.png"
import instagram from "../../assets/img/instagram.png"

const NewFooter = () => {
    return (

        <Foot>
            <div>
                <a href="https://www.facebook.com/astroquiroga">
                    <img
                        src={facebook}
                        alt="logo facebook"
                    />
                </a>
                <a href="https://www.instagram.com/oscar_o_humanista/?hl=pt-br">
                    <img
                        src={instagram}
                        alt="logo instagran"
                    />
                </a>
            </div>

            <p>
                Sistema de cálculo e interpretação astrológicas de Oscar Quiroga, o
                Astrólogo e Humanista
            </p>
            <p>
                Copyright ©2022
            </p>
        </Foot>
    );
};
export default NewFooter;
