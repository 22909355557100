import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_BACKEND
});

const USER = "@member_info";

api.interceptors.request.use((config) => {
  const storagedUser = JSON.parse(localStorage.getItem(USER));

  if (storagedUser) {
    const token = storagedUser.access_token;
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use((response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 ) {
      localStorage.removeItem(USER);
      window.location.href = '/login';
    }
  });

export default api;
