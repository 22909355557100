
import { Button } from "@material-ui/core";
import { Head } from "./styles"
import { useHistory } from "react-router-dom";
import { goToHome, goToUser} from "../../config/cordination"
import MenuOpen from "../MenuOpen";

const NewHeader = () => {
    const history = useHistory()
    return (
        <Head>
            <div>
                <Button variant="contained" color="secondary"  onClick={() => goToHome(history)} >Home</Button>
                <Button variant="contained" color="secondary" onClick={()=>goToUser(history)} >Sete Raios</Button>
                <MenuOpen />
            </div>
        </Head>
    );
};
export default NewHeader;
