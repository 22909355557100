import React, { useEffect, useState, useRef, useCallback } from "react";
import Input from "../../components/Input";
import BirthInfoService from "../../service/BirthInfoService";
import { Form } from "@unform/web";
import { Button, Modal, notification,Table, Tooltip } from "antd";
import {
    CalculatorOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import Utils from "../../utils";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import PaymentService from "../../service/PaymentService";
import { WrapperMain } from "./styles"
import NewHeader from "../../components/NewHeader";
import NewFooter from "../../components/NewFooter";

const libraries = ["places"];
const USER = "@member_info";

export default function BirthInfo() {
    const [columns, setColumns] = useState([]);
    const [birthDataSource, setBirthDataSource] = useState([]);
    const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
    const [isModalInsertVisible, setIsModalInsertVisible] = useState(false);
    const formRef = useRef(null);
    const modalWidth = 1000;
    const storagedUser = JSON.parse(localStorage.getItem(USER));
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    useEffect(() => {
        setColumns([
            {
                title:"",
                dataIndex: "ID",
                key: "ID",
                className: "column-birth",
            },

            {
                title: "Opções",
                key: "operation",
                render: (birth_info) => (
                   
                    <WrapperMain> console.log(birth_id)
                        <Tooltip placement="top" title={"Calcular"}>
                            <Button
                                onClick={() => {
                                    Modal.info({
                                        title: "Cálculos dísponíveis para a data:",
                                        closable: "true",
                                        onOk() {
                                        },
                                        okText: "Fechar",
                                        width: modalWidth,
                                        content: (
                                            <>
                                                <Form initialData={birth_info}>
                                                    <div id={"form-register"}>
                                                        <div id={"form-item"}>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="ID"
                                                                type="number"
                                                                hidden
                                                                readOnly
                                                            />
                                                            <label className={"text-body"}>Nome:</label>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="name"
                                                                type="text"
                                                                readOnly
                                                            />
                                                            <label className={"text-body"}>
                                                                Data de nascimento:
                                                            </label>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="birthDate"
                                                                type="date"
                                                                readOnly
                                                            />
                                                            <label className={"text-body"}>
                                                                Horário do nascimento:
                                                            </label>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="birthTime"
                                                                type="time"
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div id={"vert-line"} />

                                                        <div id={"form-item"}>
                                                            <label className={"text-body"}>
                                                                Local de nascimento:
                                                            </label>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="searchLocal"
                                                                type="text"
                                                                readOnly
                                                            />
                                                            <label className={"text-body"}>
                                                                Fuso Horário:
                                                            </label>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="timeZoneName"
                                                                type="text"
                                                                readOnly
                                                            />
                                                            <label className={"text-body"}>Latitude:</label>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="coordinatesLat"
                                                                type="text"
                                                                readOnly
                                                            />
                                                            <label className={"text-body"}>Longitude:</label>
                                                            <Input
                                                                id={"input-default"}
                                                                className={"text-body"}
                                                                name="coordinatesLng"
                                                                type="text"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>
                                                <Button
                                                    id={"btn-default"}
                                                    className={"text-btn"}
                                                    onClick={() => {
                                                        calculateNatal(birth_info.ID);
                                                    }}
                                                >
                                                    Cálculo astrológico
                                                </Button>

                                                <Button
                                                    id={"btn-default"}
                                                    className={"text-btn"}
                                                    onClick={() => {
                                                        calculateSevenRays(birth_info.ID);
                                                    }}
                                                >
                                                    Sete raios
                                                </Button>

                                                <Button
                                                    id={"btn-default"}
                                                    className={"text-btn"}
                                                    onClick={() => {
                                                        calculateTransitsAndProgressions(birth_info.ID);
                                                    }}
                                                >

                                                    Trânsitos e progressões
                                                </Button>
                                            </>
                                        ),

                                    });
                                }}
                            >
                                <CalculatorOutlined />
                            </Button>
                        </Tooltip>

                        <Tooltip placement="top" title={"Excluir"}>
                            <Button
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Deseja remover o registro?",
                                        icon: <ExclamationCircleOutlined />,
                                        okText: "Sim",
                                        cancelText: "Não",
                                        visible: isDeleteConfirmVisible,
                                        onCancel: () => {
                                            setIsDeleteConfirmVisible(false);
                                        },
                                        onOk: () => {
                                            setIsDeleteConfirmVisible(false);
                                            BirthInfoService.delete(birth_info.ID)
                                                .then(() => {
                                                    BirthInfoService.getAll()
                                                        .then((success) => {
                                                            setBirthDataSource(success.data);
                                                        })
                                                        .catch((error) => {
                                                            console.log("Error: ", error);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log("Error: ", error);
                                                });
                                        },
                                    });
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </WrapperMain>
                ),
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Data",
                dataIndex: "birthDate",
                key: "birthDate",
                render: (date) => {
                    return date.split("-").reverse().join("/");
                },
            },
            {
                title: "Localização",
                dataIndex: "timeZoneName",
                key: "timeZoneName",
            },

        ]);

        BirthInfoService.getAll()
            .then((success) => {
                setBirthDataSource(success.data);
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    }, [calculateSevenRays, isDeleteConfirmVisible, calculateSevenRays]);

    const showModalInsert = () => {
        setIsModalInsertVisible(true);
    };

    const handleOkInsert = async () => {
        await BirthInfoService.save(
            formatDataToBirthData(formRef.current.getData()),
            "ID"
        )
            .then(() => {
                notification.success({
                    message: "Sucesso!",
                    description: "Suas alterações foram salvas.",
                });
                formRef.current.reset();
            })
            .catch((error) => {
                notification.error({
                    message: "Erro!",
                    description: "Não foi possível salvar uma nova data de nascimento!",
                });
                console.log("Error: ", error);
            });
        setIsModalInsertVisible(false);

        window.location.reload();
    };

    const handleCancelInsert = () => {
        window.location.reload();
        setIsModalInsertVisible(false);
    };

    function formatDataToBirthData(data) {
        let date = data.birthDate.split("-");
        let time = data.birthTime.split(":");

        let coordinateLat = data.coordinatesLat.match(
            /(\d?\d)º\s(\d?\d)\'\s(\d?\d)\'\'\s(\w)/
        );
        let coordinateLng = data.coordinatesLng.match(
            /(\d?\d)º\s(\d?\d)\'\s(\d?\d)\'\'\s(\w)/
        );
        data.ew = coordinateLng ? coordinateLng[4].toLowerCase() : "";
        data.ew = data.ew === "e" ? 1 : -1;

        data.ns = coordinateLat ? coordinateLat[4].toLowerCase() : "";
        data.ns = data.ns === "n" ? 1 : -1;

        data.year = date ? date[0] : "";
        data.month = date ? date[1] : "";
        data.day = date ? date[2] : "";
        data.hour = time ? time[0] : "";
        data.minute = time ? time[1] : "";
        data.long_deg = coordinateLng ? coordinateLng[1] : "";
        data.long_min = coordinateLng ? coordinateLng[2] : "";
        data.lat_deg = coordinateLat ? coordinateLat[1] : "";
        data.lat_min = coordinateLat ? coordinateLat[2] : "";
        data.timeZoneName = data.timezone;
        data.timezone = data.rawOffset / 60 / 60;
        data.entered_by = storagedUser.username;

        return data;
    }

    function calculateSevenRays(ID) {
        const customId = {
            birth_id: ID,
            member_email: storagedUser.email,
        };
        PaymentService.get(customId)
            .then((success) => {
                if (
                    Object.keys(success.data).length !== 0 &&
                    success.data.status === "COMPLETED"
                ) {
                    window.open("/rays/" + ID, "_blank");
                    return false;
                }
                window.open("/payment/" + ID, "", "width=500, height=500");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function calculateNatal(id) {
        window.open("/natal/" + id, "_blank");
    }

    function calculateTransitsAndProgressions(id) {
        window.open("/transits/" + id, "_blank");
    }


    return (
        <>
            <NewHeader />
            <WrapperMain>
                <Button

                    onClick={showModalInsert}
                >
                    Cadastrar Nova Data
                </Button>
                <div className={"card-list-member"}>
                    <Table
                        id={"member-table"}
                        bordered={true}
                        columns={columns}
                        dataSource={birthDataSource}
                        rowKey="ID"
                        scroll={{ x: true }}
                        pagination={false}
                        title={() =>
                            "Que a graça seja contigo " +
                            storagedUser.username +
                            ", este é o seu banco de dados astrológico."
                        }
                    />
                    <Table
                        id={"member-table2"}
                        bordered={true}
                        columns={columns}
                        dataSource={birthDataSource}
                        size={"small"}
                        rowKey="ID"
                        scroll={{ x: true }}
                        pagination={false}
                        title={() =>
                            "Que a graça seja contigo " +
                            storagedUser.username +
                            ", este é o seu banco de dados astrológico."
                        }
                    />
                </div>

                <Modal
                    title={<p className={"text-title"}>Inserir uma nova data</p>}
                    visible={isModalInsertVisible}
                    onOk={handleOkInsert}
                    okText={"Inserir"}
                    onCancel={handleCancelInsert}
                    cancelText={"Cancelar"}
                    width={1300}
                >
                    {/*<p className={"text-body"} style={{marginBottom: 20}}>*/}
                    {/*  É importante verificar os dados inseridos para evitar o risco de calcular o Mapa errado.*/}
                    {/*  Depois de ingressar o nome da cidade, a longitude e a latitude será inserido automaticamente.*/}
                    {/*</p>*/}

                    {loadError || !isLoaded ? (
                        <div>Carregando...</div>
                    ) : (
                        <SearchLocal formRef={formRef} />
                    )}
                </Modal>
            </WrapperMain>
            <NewFooter />
        </>
    );
}

function SearchLocal(props) {
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null,
    });
    const { formRef } = props;

    const [timeZoneId, setTimeZoneId] = useState("");
    const [timeZoneName, setTimeZoneName] = useState("");
    const [rawOffset, setRawOffset] = useState("");
    const [hour, setHour] = useState("");
    const [date, setDate] = useState("");

    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({
        lat: -3.745,
        lng: -38.523,
    });

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });

    const containerStyle = {
        width: "100%",
        height: "90%",
        marginTop: "5%",
    };

    const googleOptions = {
        disableDefaultUI: true,
        zoomControl: true,
    };

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect = ({ description }) => () => {
        setValue(description, false);
        clearSuggestions();

        getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setCoordinates({ lat, lng });
                setCenter({ lat, lng });

                let dateStr = date + " " + hour + ":00";
                let timestamp = Math.floor(+new Date(dateStr).getTime() / 1000);

                BirthInfoService.getTimeZone(lat, lng, timestamp)
                    .then((success) => {
                        if (success.data.status === "INVALID_REQUEST") {
                            notification.error({
                                message: "Aviso!",
                                description: "Houve um erro ao buscar o fuso horário!",
                            });

                            return false;
                        }

                        setTimeZoneId(success.data.timeZoneId);
                        setTimeZoneName(success.data.timeZoneName);
                        setRawOffset(success.data.rawOffset + success.data.dstOffset);
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <div
                    id={"input-default"}
                    key={place_id}
                    onClick={handleSelect(suggestion)}
                >
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </div>
            );
        });

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    const handleDate = (e) => {
        setDate(e.target.value);
    };

    const handleTime = (e) => {
        setHour(e.target.value);
    };

    return (
        <Form ref={formRef} autoComplete="off">
            <div id={"form-register"}>

                <div id={"form-item"}>
                    <Input name="ID" type="number" hidden />
                    <label className={"text-body"} style={{ marginBottom: 5 }}>
                        Nome:<label style={{ marginBottom: 5, color: "red" }}> *</label>
                    </label>
                    <Input
                        id={"input-default"}
                        placeholder={"INSIRA O NOME AQUI"}
                        name="name"
                        type="text"
                    />
                    <label className={"text-body"} style={{ marginBottom: 5 }}>
                        Data de nascimento:
                        <label style={{ marginBottom: 5, color: "red" }}> *</label>
                    </label>
                    <Input
                        id={"input-default"}
                        name="birthDate"
                        type="date"
                        onBlur={handleDate}
                        onChange={() => {
                        }}
                    />
                    <label className={"text-body"} style={{ marginBottom: 5 }}>
                        Horário do nascimento:
                        <label style={{ marginBottom: 5, color: "red" }}> *</label>
                    </label>
                    <Input
                        id={"input-default"}
                        name="birthTime"
                        type="time"
                        onBlur={handleTime}
                        onChange={() => {
                        }}
                    />

                    <label className={"text-body"} style={{ marginBottom: 5 }}>
                        Local de nascimento:
                        <label style={{ marginBottom: 5, color: "red" }}> *</label>
                    </label>
                    <Input
                        name={"searchLocal"}
                        id={"input-default"}
                        value={value}
                        onChange={handleInput}
                        disabled={!ready}
                        placeholder="INSIRA SUA CIDADE DE NASCIMENTO"
                    />
                    {status === "OK" && <div id={"div-local"}>{renderSuggestions()}</div>}

                    <label className={"text-body"} style={{ marginBottom: 5 }}>
                        Fuso Horário:
                    </label>
                    <Input
                        id={"input-default"}
                        name={"timezone"}
                        value={
                            timeZoneId && timeZoneName ? timeZoneId + " " + timeZoneName : ""
                        }
                        readOnly
                    />
                    <Input name={"rawOffset"} value={rawOffset} readOnly hidden />
                    <label className={"text-body"} style={{ marginBottom: 5 }}>
                        Latitude:
                    </label>
                    <Input
                        id={"input-default"}
                        name={"coordinatesLat"}
                        value={coordinates.lat ? Utils.ConvertDMSLat(coordinates.lat) : ""}
                        readOnly
                    />
                    <Input
                        name={"coordinatesLatDec"}
                        value={coordinates.lat || ""}
                        hidden
                    />
                    <label className={"text-body"} style={{ marginBottom: 5 }}>
                        Longitude:
                    </label>
                    <Input
                        name={"coordinatesLng"}
                        id={"input-default"}
                        value={coordinates.lng ? Utils.ConvertDMSLng(coordinates.lng) : ""}
                        readOnly
                    />
                    <Input
                        name={"coordinatesLngDec"}
                        value={coordinates.lng || ""}
                        hidden
                    />
                </div>

                <div id={"vert-line"} />

                <div id={"form-item"}>
                    <GoogleMap
                        id="circle-example"
                        mapContainerStyle={containerStyle}
                        options={googleOptions}
                        zoom={13}
                        center={center}
                        onUnmount={onUnmount}
                    >
                        <Marker
                            position={{ lat: coordinates.lat, lng: coordinates.lng }}
                        />
                    </GoogleMap>
                </div>
            </div>
        </Form>
    );
}
