import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Input } from "@material-ui/core";

export default function Inputitem({ name, label, ...rest }) {
    const inputRef = useRef(null);

    const { fieldName, defaultValue = "", registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
        });
    }, [fieldName, registerField]);

    return (
        <>
            {label && <label htmlFor={fieldName}>{label}</label>}

            <Input
              
              inputRef={inputRef}
              id={fieldName}
              defaultValue={defaultValue}
              {...rest}
            />

            {error && <span style={{ color: "#ff0000" }}>{error}</span>}
        </>
    );
}