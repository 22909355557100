import styled from "styled-components";
import background from "../../assets/img/background.png";

export const WrapperMain = styled.main`
  display:flex ;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  align-items: flex-end;
  background: url(${background});
  box-shadow: inset 0 0 0 1300px rgba(255, 255, 255, 0.5);
  background-size: 100vw auto;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: "Montserrat" !important;
  color:#77051B;
  h1{
      text-align: center;
  }

  @media (max-width: 800px) {
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 0px;
        margin: 0px;
        width: 90vw;
        height: auto;
        font-size:08px;
    } 
  `
export const AudioItem = styled.div`
    display: flex;
    justify-content: center;
    width:100%;
    height: 5vh;
    align-items: flex-start;
    margin-bottom: 20px;

    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
        align-content: start;
        width: 100vw;  

    } 

`
export const VideoItem = styled.div`
    display: flex;
    width: 50vw;
    height: 20vh;
    max-width:500px;
    align-items: flex-start;

    @media (max-width: 800px) {
        display: flex;
        align-self: center;
        padding: 0px;
        margin: 0px;
        width: 80vw;


    } 

`