import styled from "styled-components";
import bg from "../../assets/img/bg.png";

export const Main = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  min-height: 80vh;
  background: url(${bg});
  box-shadow: inset 0 0 0 1300px rgba(255, 255, 255, 0.5);
  background-size: 100vw auto;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: "Montserrat" !important;
  padding-right: 10%;

h1{
display: flex;
justify-content: center;
align-items: center;
margin-top: 150px;
}

div{
    display: grid;
    justify-items:center;
    justify-content: center;
    align-items: center;
    background-color: gray;
    opacity: 50%;
    border-radius: 10px;
    width: 60%;    
    height: 50vh;
    margin:auto;
    padding: 25px;
}
`