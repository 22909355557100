export const goToSignUp= (history)=>{
    history.push("/signup")
};
export const goToLogin = (history)=>{
    history.push("/login")
};
export const goToHome = (history)=>{
    history.push("/home")
};
export const goToUser = (history)=>{
    history.push("/profile")
}
export const goToNewData = (history)=>{
    history.push("/newdata")
}
export const goToConfig = (history)=>{
    history.push("/config")
}
export const goToLogout = (history)=>{
    history.push("/home")
}
export const goToRecover= (history)=>{
    history.push("/recover")
}
export const goToSeteMapas= (history)=>{
    history.push("/setemapas")
}
export const goToRays= (history)=>{
    history.push("/rays/:id")
}