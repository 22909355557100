
import styled from "styled-components";


export const Foot = styled.div`

  display: flex;
  justify-content: space-around;
  height: 10vh;
  background-color: #77051b;
  align-items: center;
  max-width: 100vw;
  box-shadow: 1px 1px 5px 1px;
  div {
   display: flex;
  }
  img {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 800px) {
    p{
       font-size: 0.5em; 
    } 
  }
`;
