import React from 'react';
import "./style.css";

export default function Template() {
  return (
    <>
      <div id={"body-template"}>
        <div id={"card-template"}>
          <div className={"error"}>
            <h1 className={"text"}> Page modelo!</h1>
            <h2 className={"text"}> Card para page</h2>
          </div>
        </div>
      </div>
    </>
  );
}
