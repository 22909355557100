import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SettingsIcon from '@material-ui/icons/Settings';
import AcUnit from '@material-ui/icons/AcUnit';
import { goToConfig, goToRays } from '../../config/cordination';
import { useHistory } from 'react-router-dom';
import { useAuth } from "../../contexts/auth";
import {notification } from "antd";


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const MenuOpen=()=> {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory()
  const { signOut } = useAuth();

  function handleLogout() {
    signOut();
    notification.success({
      message: "Sucesso!",
      description: "Deslogado!",
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <section>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="secondary"
        onClick={handleClick}
      >
        Usuário
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem >
          <ListItemIcon onClick={()=>goToRays(history)}>
            <AcUnit fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Meus Mapas" />
        </StyledMenuItem>

        <StyledMenuItem onClick={()=>goToConfig(history)}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
        </StyledMenuItem>


        <StyledMenuItem onClick={handleLogout}>
          <ListItemIcon>
            <InboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </StyledMenuItem>
      </StyledMenu>
    </section>
  );
}

export default MenuOpen