import { Main } from "./styles"
import TermsAndConditions from "../../components/TermsAndConditions"

const Terms = () => {
    return (
        <div>
            <Main>
                <TermsAndConditions />
            </Main>

        </div>)
}

export default Terms;