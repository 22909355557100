import NewHeader from "../../components/NewHeader"
import NewFooter from "../../components/NewFooter"
import { Main, Container } from "./styles"
import nome from "../../assets/img/nome.png"
import titulo from "../../assets/img/titulo.png"


const NewHome = () => {
    const URL_VIDEO = 'https://www.youtube.com/watch?v=R_2QJDnKhzs'
    return (
        <div>
            <NewHeader />
            <Main>
                <Container>
                    <h1>Bem Vindo ao portal Oscar Quiroga</h1>
                    <iframe
                        title={"video"}
                        scrolling="no"
                        height="500"
                        frameBorder="no"
                        allow="autoplay"
                        src={"https://www.youtube.com/embed/ozVkG8KH3UM"}
                    ></iframe>
                    <div>
                        <img src={nome} alt={''} />
                        <img src={titulo} alt={''} />
                    </div>
                </Container>
            </Main>

            <NewFooter />

        </div>
    )
}

export default NewHome