import React from "react";
import "./style.css";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

export default function Footer() {
  return (
    <footer id={"footer"}>
      <p className={"content-footer"}>
        <a
          href={"https://www.facebook.com/astroquiroga/"}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <FacebookOutlined style={{ fontSize: 20, marginRight: 4 }} />
        </a>
        <a
          href={"https://www.instagram.com/oscar_o_humanista/"}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <InstagramOutlined style={{ fontSize: 20, marginRight: 4 }} />
        </a>
        Sistema de cálculo e interpretação astrológicas de Oscar Quiroga, o
        Astrólogo e Humanista
      </p>
      <p className={"content-footer"}>
        Para dúvidas fale conosco{" "}
        <em>
          <a href="mailto:quiroga@example.com">quiroga@example.com</a>
        </em>
      </p>
    </footer>
  );
}
