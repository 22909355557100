import styled from "styled-components";

export const Head = styled.div`
  display: flex;
  justify-content: space-around;
  align-content:center;
  height: 10vh;
  padding: 0px;
  margin:0px;
  max-width:100vw;
  background-color: #77051b;
  align-items: center;
  box-shadow: 1px 1px 5px 1px;

  div {
    display: flex;
    width: 40vw;
    min-width: 240px;
    max-width: 360px;
    justify-content: space-around;
  }
   img{
      width: 250px;
  }
  @media (max-width: 800px) {
     div{
      display:flex ;
      justify-content:space-around ;
      width: 100vw;
      
     }
      button{
          width:auto;
          font-size: 1em;
      }
  `;
