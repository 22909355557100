import Service from "./Service";
import api from "./api";
import bcryptjs from "bcryptjs";

const SALT_WORK_FACTOR = 12;

export default class MemberInfoService extends Service {
  static service = "/member_info/";

  static async getAll(params) {
    return await api.get(`${this.service}`, params);
  }

  static async get(id) {
    return await api.get(`${this.service}${id}`);
  }

  static async create(data) {
    return await api.post(`${this.service}`, data);
  }

  static async update(id, data) {
    return await api.put(`${this.service}${id}`, data);
  }

  static async delete(id) {
    return await api.delete(`${this.service}${id}`);
  }

  // identifica se precisa de uptade ou create
  static async save(data, idAttribute = "id") {

    const id = data[idAttribute];

    //converter o password normal em hash
    if (data.password.length < 60) {
      let salt = bcryptjs.genSaltSync(SALT_WORK_FACTOR);
      data.password = bcryptjs.hashSync(data.password, salt);
    }

    let url = "/member_info";
    let method = "post";

    if (id) {
      url += `/${id}`;
      method = "put";
    }

    return await api[method](url, data);
  }

  static async getNatal(){
    return api.get("/natal");
  }
}
