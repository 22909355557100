import Service from "./Service";
import api from "./api";
import axios from "axios";

const USER = "@member_info";
export default class BirthInfoService extends Service {
  static service = "/birth_info";

  static async getAll() {
    const storagedUser = JSON.parse(localStorage.getItem(USER));
    return await api.get(`${this.service}?username=${storagedUser.username}`);
  }

  static async create(data) {
    return await api.post(`${this.service}`, data);
  }

  static async update(id, data) {
    return await api.put(`${this.service}/${id}`, data);
  }

  static async delete(id) {
    return await api.delete(`${this.service}/${id}`);
  }

  // identifica se precisa de uptade ou create
  static async save(data, idAttribute = "id") {
    const id = data[idAttribute];

    let url = "/birth_info";
    let method = "post";

    if (id) {
      url += `/${id}`;
      method = "put";
    }

    return await api[method](url, data);
  }

  static async getTimeZone(lat, lng, timestamp) {
    let latlng = lat + "," + lng;

    return await axios({
      method: "post",
      url: "https://maps.googleapis.com/maps/api/timezone/json",
      params: {
        location: latlng,
        timestamp: timestamp,
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: "pt-BR",
      },
    });
  }

  static async getSevenRays(id) {
    const storagedUser = JSON.parse(localStorage.getItem(USER));
    var bodyFormData = new FormData();
    bodyFormData.append("id1", id);
    bodyFormData.append("username", storagedUser.username);
    return axios.post("https://aastrologia.ml/scripts/rays.php", bodyFormData);
  }

  static async getNatal(id) {
    const storagedUser = JSON.parse(localStorage.getItem(USER));
    var bodyFormData = new FormData();
    bodyFormData.append("id1", id);
    bodyFormData.append("username", storagedUser.username);
    return axios.post("https://aastrologia.ml/scripts/natal.php", bodyFormData);

    // return await axios({
    //   method: "post",
    //   headers: {
    //     "Content-Type": `multipart/form-data;`,
    //   },
    //   url: "http://oastrologo.ml/scripts/natal.php",
    //   params: bodyFormData,
    // });
  }

  static async getTransitsAndProgressions() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Trânsitos e Progressões Calculado");
      }, 3000);
    });
  }
}
