import React from 'react';
import Robot from "../../assets/img/robot-error.webp";
import "./style.css";

export default function NotFound() {
  return (
    <>
      <div id={"body-error"}>
        <div id={"card-error"}>
          <div className={"error"}>
            <h1 className={"text"}> Whoops!</h1>
            <img src={Robot} style={{width: 400}} alt="Oscar Quiroga, o Astrólogo e Humanista"/>
            <h2 className={"text"}> Algo de errado aconteceu.</h2>
            <h1 className={"text-link"}>
              <a href="/">
                Clique aqui para voltar a Home
              </a>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}
