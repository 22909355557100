import React, { useState, useEffect, useRef } from "react";
import PaymentService from "../../service/PaymentService";
import { NavLink } from "react-router-dom";
import { PayPalItem } from "./styles"
import { Button } from "antd";

export default function Paypal(props) {

    const [paid, setPaid] = useState(false);
    const [loaded, setLoaded] = useState(false);
    let paypalRef = useRef();
    const USER = "@member_info";
    const storagedUser = JSON.parse(localStorage.getItem(USER));
    const product = {
        price: 1.99,
        description: "Assinatura Quiroga",
    }
    const { birth_ID } = props;

    useEffect(() => {
        const script = document.createElement("script");
        const id = "AdXb--4YISs-tHNsqUa540bL2-lrEnXRRhyVD6Axb9JcWWcdUgJ7m9rBOYzNQFVfXNze2m75AXik8IJD";
        script.src = `https://www.paypal.com/sdk/js?currency=BRL&client-id=${id}`

        script.addEventListener("load", () => setLoaded(true));

        document.body.appendChild(script);

        if (loaded && storagedUser) {
            function loadButtonsAndPayment() {
                setTimeout(() => {
                    window.paypal
                        .Buttons({
                            createOrder: (data, actions) => {
                                return actions.order.create({

                                    purchase_units: [
                                        {
                                            custom_id: storagedUser.email + "#" + birth_ID,
                                            description: product.description,
                                            amount: {
                                                currency_code: "BRL",
                                                value: product.price
                                            }
                                        }
                                    ]
                                });
                            },
                            onApprove: async (_, actions) => {
                                const order = await actions.order.capture();
                                setPaid(true);
                                PaymentService.save(order)
                                    .then(
                                        (success) => {
                                            console.log(success.data);
                                        }
                                    )
                                    .catch(
                                        (error) => {
                                            console.log(error);
                                        }
                                    );
                            }
                        })
                        .render(paypalRef);
                })
            }

            loadButtonsAndPayment();
        }
    })
    return (
        <PayPalItem >
            <div>
                {paid ? (
                    <div>
                        <h1 > Assinatura comprada com sucesso!</h1>
                        <NavLink to={"/rays/" + birth_ID}>
                            <Button variant="contained"
                                color="primary">Clique aqui se quiser visualizar seu cálculo agora mesmo!</Button>
                        </NavLink>
                    </div>
                ) : (
                    <>
                        <div>
                            <h1 >
                                {product.description} por R${product.price}
                            </h1>
                        </div>
                        <div >
                            <div ref={v => (paypalRef = v)} />
                        </div>
                    </>
                )}

            </div>
        </PayPalItem>
    );
}