import { Button, CircularProgress } from "@material-ui/core"
import NewHeader from "../../components/NewHeader"
import NewFooter from "../../components/NewFooter"
import { Main, Contents } from "./styles"
import { goToNewData } from "../../config/cordination"
import { useHistory } from "react-router-dom"
import DenseAppBar from "../../components/DataBar"
import { useState } from "react"


const NewProfile = () => {

    const history = useHistory()
    const USER = "@member_info";
    const storagedUser = JSON.parse(localStorage.getItem(USER));
    const [birthInfo, setBirthInfo] = useState([{name:"jullia"}])//passar pra ca os dados cadastrados

    const showInfo = birthInfo&&birthInfo.map((item)=>{
        return <DenseAppBar name = {item.name} date={item.date}/>
    })


    return (
        <div>
            <NewHeader />
            <Main>
                <div className="all-contents">
                    <Button onClick={() => goToNewData(history)}
                        variant="contained"
                        color="primary">
                        Cadastrar
                    </Button>
                    {/* estou tentando fazer o condicional para quando n tiver dados */}
                    <Contents>
                        { birthInfo? showInfo : <div><CircularProgress/>Você ainda não cadastrou datas</div>}
                        <h4 style={{textAlign:'center', alignSelf:'end'}}>{`Que a graça seja contigo 
                            ${storagedUser.username}
                            , este é o seu banco de dados astrológico.`}</h4>
                    </Contents>
                </div>
            </Main>

            <NewFooter />
        </div>
    )
}
export default NewProfile