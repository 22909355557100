import React, { useState } from "react";
import Input from "../../components/Input";
import { Form } from "@unform/web";
import { notification } from "antd";
import { useAuth } from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { WrapperMain, ContainerLogin } from "./styles";
import icone from "../../assets/img/icone.png";
import titulo from "../../assets/img/titulo.png";
import nome from "../../assets/img/nome.png";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { goToRecover, goToSignUp } from "../../config/cordination";

const LoginPage = () => {
    const { signIn } = useAuth();
    const history = useHistory();
    const [loadings, setLoadings] = useState('false');
    const [terms, setTerms] = useState('true')
    console.log(terms)
    console.log("login",loadings)

    const handleTerms = () => {
        setTerms(!terms)
    }
    const handleSubmit = async (data) => {
        if (terms === 'true') { 
            setLoadings('true'); }

        await signIn(data)
            .then((response) => {
                if (response.data.warning) {
                    response.data.warning.forEach((item) => {
                        notification.warning({
                            message: "Aviso!",
                            description: item,
                        });
                    });
                    return false;
                }
                notification.success({
                    message: "Sucesso!",
                    description: "Logado com sucesso!",
                });
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        setLoadings('false');
        history.push("/");
        //todo ver erro de memory leak
    };

    return (
        <WrapperMain>
            <ContainerLogin>
                <div className="img-container">
                    <img src={icone} alt="imagem-logo" />
                    <img src={nome} alt="imagem-logo" />
                    <img src={titulo} alt="imagem-logo" />
                </div>

                <Form className={"container-form"} onSubmit={handleSubmit}>
                    <Input
                        className={"input-item"}
                        name="email"
                        placeholder="EMAIL"
                        type="email"
                    />
                    <Input
                        className={"input-item"}
                        name="password"
                        placeholder="SENHA"
                        type="password"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        id={"btn-default"}
                        className={"text-btn btn-default"}
                        type="submit"
                        loading={loadings}
                    >
                        Login
                    </Button>

                    <div className="container-links">
                        <div>
                            <FormControlLabel
                                control={<Checkbox color="primary"/>}
                                label={"Manter-me Logado"}
                            />
                            <h4 onClick={() => goToRecover(history)}>Esqueceu a Senha?</h4>
                        </div>

                        <div>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={terms} onClick={handleTerms}  />}
                                label={
                                    <>
                                        Aceitar os <a href="/termos">Termos de Uso</a>
                                    </>
                                }
                            />
                            <h4 onClick={() => goToSignUp(history)}>Cadastre-se</h4>
                        </div>
                    </div>
                </Form>
            </ContainerLogin>
        </WrapperMain>
    );
};
export default LoginPage;
