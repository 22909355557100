import React from "react";
import {useParams} from "react-router-dom";
import Footer from "../../components/Footer";
import Paypal from "../../components/Paypal";
import { WrapperMain, ContainerPayPall } from "./styles";
import NewFooter from "../../components/NewFooter";
import NewHeader from "../../components/NewHeader"

export default function Payment(){
  const {id} = useParams();
  console.log(id)
  return(
    <>
    <NewHeader/>
      <WrapperMain>
          <ContainerPayPall>
        <Paypal birth_ID = {id}/>      
         </ContainerPayPall>  
      </WrapperMain>
      <NewFooter/>
    </>
  );
}

