
import styled from "styled-components";
import bg from "../../assets/img/bg.png"

export const Main = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 80vh;
  background: url(${bg});
  box-shadow: inset 0 0 0 1300px rgba(255, 255, 255, 0.5);
  background-size: 100vw auto;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: "Montserrat" !important;

`
export const Container = styled.main`
display: flex;
flex-direction: column;
justify-content: flex-end;
text-align: center;
width: 50vw;
height: 50vh;
padding: 2%;
background-color:rgba(167,162,169,0.5);
border-radius: 5px;
margin-right: 10%;
margin-top: -5%;
h1{
    color:#77051B;
}
img{
     max-width:300px;
     }   

div{
    display: grid;
    justify-items: center;
}
@media (max-width: 800px) {
    display: flex;
    align-self: center;
    justify-content:center;
    height: 60vh;
    width: 80vw;
    }
    img{
        width: 40%;
    }
    h1{
        font-size: 1em;
    }
`