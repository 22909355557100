import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import MemberInfoService from "../../service/MemberInfoService";
import { WrapperMain, AudioItem, VideoItem } from "./styles";
import NewFooter from "../../components/NewFooter";
import NewHeader from "../../components/NewHeader"

export default function Transits() {
    return (
        <>
            <NewHeader />
            <WrapperMain>
                <AudioItem>
                    <iframe
                        title={"audio"}
                        scrolling="no"
                        width={'100%'}
                        height={'100%'}
                        frameBorder="no"
                        allow="autoplay"
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/229953143&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                    ></iframe>
                </AudioItem>
                
                <VideoItem>
                    <iframe
                        title={"video"}
                        width={'100%'}
                        height={'100%'}
                        frameBorder="no"
                        allow="autoplay"
                        src="https://www.youtube.com/embed/ozVkG8KH3UM"
                    ></iframe>
                </VideoItem>



            </WrapperMain>
            <NewFooter />
        </>
    )
}