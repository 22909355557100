import styled from "styled-components";
import background from "../../assets/img/background.png"

export const Main = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 80vh;
  background: url(${background});
  box-shadow: inset 0 0 0 1300px rgba(255, 255, 255, 0.5);
  background-size: 100vw auto;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: "Montserrat" !important;

button{
    justify-content: center;
    width: 200px;
}
.all-contents{
    margin-top: -10%;
    @media (max-width: 800px) {

button{
    width: auto;
    max-width: 200px;
}
    }
}
`
export const Contents = styled.div`
    background-color: rgba(167,162,169,0.5);
    display: grid;
    width: 50vw;
    height: auto;
    min-height: 40vh;
    padding: 2%; 
    margin-right: 200px;
    @media (max-width: 800px) {
        display: grid;
        justify-content: center;
        width: 90%;  
    }`



