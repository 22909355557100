import styled from "styled-components";
import background from "../../assets/img/background.png";

export const WrapperMain = styled.main`

  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: url(${background});
  box-shadow: inset 0 0 0 1300px rgba(255, 255, 255, 0.5);
  background-size: 100vw auto;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: "Montserrat" !important;
  padding-right: 10%;

  h4,
  a {
    color: #77051b;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    font-family: "Questrial";
    text-transform: uppercase;
  }
  span {
    text-align: center;
  }
  .btn-default {
    height: 40px;
    width: 100%;
    margin: 1vh;
    padding: 1vh;
  }

  .btn-secundary {
    height: 56px;
    width: 30%;
    margin: 1vh;
    font-size: 0.8rem;
    text-align: center !important;
  }
  
  .container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat" !important;
    .input-item {
      width: 100%;
      background-color: #ffffff;
      align-items: center;
     // border: 1px solid gray;
      border-radius: 5px;
      margin: 1vh;
      padding: 1vh;
      height: 48px;
    } 
      
    }
  }
  .container-links {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 2.5rem;
    }
  }
  .services {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    min-width: 300px;
    flex-wrap: wrap;
    font-size: 0.8rem;
  }
  .small-container {
    width: 90%;
    display: grid;
    justify-items: center;
    justify-content: center;
    img {
      width: 50px;
    }
    span {
      font-size: 12px;
    }
    ul {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    li {
      font-size: 10px;
    }
  }
  .big-container {
    text-align: center;
    width: 100%;
    div {
      margin-bottom: 20px;
    }
    h1 {
      color: #77051b;
      font-family: "Questrial";
      text-transform: uppercase;
    }
  }
`
export const ContainerSingUp= styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 1vw;
    width: 32vw;
    margin-right: 5%;
    background-color: #77051b;
    border-radius: 5px;
    h4 {
      color: #e2dfe2;
    }
    h1 {
      color: #e2dfe2;
      font-family: "Questrial";
      text-transform: uppercase;
    }
    img {
      height: 84px;
      margin: 1vh;
    }
    @media (max-width: 800px) {
    display: flex;
    align-self: center;
    justify-content:center;
    height: 80vh;
    width: 80vw;
    margin: 0px;
    img{
        width: 80%;
        height: auto;
    }
    h4{
        font-size: 0.7em;
    }
    }

  `
export const ContainerLogin = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 1vw;
    width: 32vw;
    margin-right: 5%;
    img {
      width: 15vh;
      height: auto;
    }
    .img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 800px) {
    display: flex;
    align-self: center;
    justify-content:center;
    height: 80vh;
    width: 80vw;
    margin: 0px;    

    img{
        width: 60%;
        height: auto;
        max-width: 300px;
    }
    span, a, h4{           
        font-size: 0.6em;
    }
    .btn-secundary{
        display: flex;
        width: 20%;
    }

    .small-container{
        display: grid;
        justify-items: center;
    }

`