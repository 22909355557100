import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import BirthInfoService from "../../service/BirthInfoService";
import { useParams } from "react-router-dom";
import { AudioItem, VideoItem, WrapperMain } from "./styles";
import NewFooter from "../../components/NewFooter";
import NewHeader from "../../components/NewHeader"

export default function Natal() {
    const { id } = useParams();
    const [map, setMap] = useState(null);

    useEffect(() => {
        BirthInfoService.getNatal(id)
            .then((success) => {
                if (!success.data.planeta) {
                    return false;
                }
                setMap(success.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <NewHeader />
            <WrapperMain>

                <AudioItem>
                    <iframe
                        title={"audio"}
                        scrolling="no"
                        width={'100%'}
                        height={'100%'}
                        frameBorder="no"
                        allow="autoplay"
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/229953143&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                    ></iframe>
                </AudioItem>

                <VideoItem>
                    <iframe
                        title={"video"}
                        width={'100%'}
                        height={'100%'}
                        frameBorder="no"
                        allow="autoplay"
                        src="https://www.youtube.com/embed/ozVkG8KH3UM"
                    ></iframe>
                </VideoItem>

                {map && (
                    <div>
                        <div id={"centralize"}>
                            <img className={"img-calculation"} src={map.imagem} />
                        </div>
                        <ul style={{ padding: 0 }} className={"astral text-body"}>
                            <div className={"div-calculation"}>
                                <li>
                                    <span style={{ fontSize: 20 }}>Planeta</span>
                                    <ul>
                                        <li>
                                            Sol: {map.planeta.Sol.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Sol.longitude[1]}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Sol.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Lua: {map.planeta.Lua.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Lua.longitude[1]}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Lua.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Mercúrio: {map.planeta.Mercrio.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Mercrio.longitude[1]}{" "}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Mercrio.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Vênus: {map.planeta.Vnus.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Vnus.longitude[1]}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Vnus.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Marte: {map.planeta.Marte.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Marte.longitude[1]}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Marte.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Júpiter: {map.planeta.Jpiter.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Jpiter.longitude[1]}{" "}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Jpiter.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Saturno: {map.planeta.Saturno.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Saturno.longitude[1]}{" "}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Saturno.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Urano: {map.planeta.Urano.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Urano.longitude[1]}{" "}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Urano.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Netuno: {map.planeta.Netuno.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Netuno.longitude[1]}{" "}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Netuno.posicao_da_casa}
                                            </ul>
                                        </li>
                                        <li>
                                            Plutão: {map.planeta.Pluto.longitude[0]}{" "}
                                            <span style={{ fontSize: 18 }}>
                                                {map.planeta.Pluto.longitude[1]}{" "}
                                            </span>
                                            <ul>
                                                Posição da Casa: {map.planeta.Pluto.posicao_da_casa}
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </div>

                            {/*<div id={"centralize"} className={"vert-calculation"} />*/}

                            <div className={"div-calculation"}>
                                <li>
                                    <span style={{ fontSize: 20 }}>Casa</span>
                                    <ul>
                                        <li>Ascendente: {map.casa.Ascendant}</li>
                                        <li>Casa 2: {map.casa.House_2}</li>
                                        <li>Casa 3: {map.casa.House_3}</li>
                                        <li>Casa 4: {map.casa.House_4}</li>
                                        <li>Casa 5: {map.casa.House_5}</li>
                                        <li>Casa 6: {map.casa.House_6}</li>
                                        <li>Casa 7: {map.casa.House_7}</li>
                                        <li>Casa 8: {map.casa.House_8}</li>
                                        <li>Casa 9: {map.casa.House_9}</li>
                                        <li>Meio do Céu: {map.casa.MC_Midheaven}</li>
                                        <li>Casa 11: {map.casa.House_11}</li>
                                        <li>Casa 12: {map.casa.House_12}</li>
                                    </ul>
                                </li>
                            </div>
                        </ul>
                    </div>
                )}



            </WrapperMain>
            <NewFooter />
        </div>
    );
}
