import React, { useState } from "react";
import { notification } from "antd";
import AuthService from "../../service/AuthService";
import { AccountCircle } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Utils from "../../utils";
import { goToLogin } from "../../config/cordination";
import LockIcon from "@material-ui/icons/Lock";
import Input from "../../components/Input";
import { Button, InputAdornment } from "@material-ui/core";
import { Form } from "@unform/web";
import { WrapperMain, ContainerSingUp } from "./styles";
import  logosilver from "../../assets/img/logosilver.png"
const SignUp = () => {
  const history = useHistory();
  const [loadings, setLoadings] = useState("false");

  const handleSubmit = async (data) => {
    if (data.password !== data.confirm) {
      notification.warning({
        message: "Aviso!",
        description: "Verifique se as senhas digitadas são iguais!",
      });
      return false;
    }
    delete data.confirm;

    if (data.username < 4) {
      notification.warning({
        message: "Aviso!",
        description: "O nome não pode ter menos que 4 letras!",
      });
      return false;
    }

    if (data.username > 12) {
      notification.warning({
        message: "Aviso!",
        description: "O nome não pode ter mais que 12 letras!",
      });
      return false;
    }

    if (!Utils.validateEmail(data.email)) {
      notification.warning({
        message: "Aviso!",
        description: "Insira um email válido!",
      });
      return false;
    }
    setLoadings("true");
    await AuthService.signUp(data)
      .then((response) => {
        if (response.data.warning) {
          response.data.warning.forEach((item) => {
            notification.warning({
              message: "Aviso!",
              description: item,
            });
          });
          return false;
        }
        notification.success({
          message: "Confirme seu endereço de e-mail!",
          description:
            "Foi enviado um email em sua caixa de mensagens, confirme para efetuar o cadastro.",
        });
        history.push("/login");
      })
      .catch((error) => {
        notification.error({
          message: "Erro!",
          description: "Não foi possível efetuar o seu cadastro!",
        });
        console.log(error);
      });
    setLoadings("false");
  };

  return (
    <WrapperMain>
      <ContainerSingUp>
        <img src={logosilver} alt="" />
        {/* <h1>Cadastre-se</h1> */}
        <Form className={"container-form"} onSubmit={handleSubmit}>
          <Input
            className={"input-item"}
            name="email"
            type="email"
            required
            placeholder="E-mail"
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle color="primary" />
              </InputAdornment>
            }
          />

          <Input
            className={"input-item"}
            name="username"
            type="text"
            required
            id="filled-required"
            placeholder="Nome"
            variant="filled"
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle color="primary" />
              </InputAdornment>
            }
          />

          <Input
            className={"input-item"}
            required
            placeholder="Senha"
            type="password"
            name="password"
            startAdornment={
              <InputAdornment position="start">
                <LockIcon color="primary" />
              </InputAdornment>
            }
          />

          <Input
            className={"input-item"}
            required
            placeholder="Confirme a senha"
            type="password"
            name="confirm"
            startAdornment={
              <InputAdornment position="start">
                <LockIcon color="primary" />
              </InputAdornment>
            }
          />

          <Button
            variant="contained"
            color="secondary"
            className={"btn-default"}
            type="submit"
            loading={loadings}
          >
            Cadastrar
          </Button>
        </Form>

        <h4 onClick={() => goToLogin(history)}>
          Já tem uma conta? Clique aqui
        </h4>
      </ContainerSingUp>
    </WrapperMain>
  );
};

export default SignUp;
