import Service from "./Service";
import api from "./api";
import bcryptjs from "bcryptjs";

const SALT_WORK_FACTOR = 12;

export default class AuthService extends Service {
  static service = "/member_info/";

  /**
   * Chamada na api para autenticar o usuario
   * @param data (email, password)
   * @return boolean
   */
  static login(data) {
    return api
      .post("/get-salt", {email: data.email})
      .then((success) => {
        const hash = bcryptjs.hashSync(data.password, success.data.salt);

        return api
          .post("/login", {email: data.email, password: hash})
          .then((success) => {
            return success;
          })
          .catch((error) => {
            throw new Error(error);
          });
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  static logout() {
    const storagedUser = JSON.parse(localStorage.getItem("@member_info"));
    const token = storagedUser.access_token;

    return api.post(
      "/logout",
      {},
      {headers: {Authorization: `Bearer ${token}`}}
    );
  }

  static signUp(data) {
    let salt = bcryptjs.genSaltSync(SALT_WORK_FACTOR);
    data.password = bcryptjs.hashSync(data.password, salt);

    return api.post("/register", data);
  }

  static recover(data) {
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("Email enviado a caixa de email");
    //   }, 3000);
    // });

    return api.post("/recover", {email_member: data.email_member});
  }

  static newPassword(data) {
    let salt = bcryptjs.genSaltSync(SALT_WORK_FACTOR);
    data.password = bcryptjs.hashSync(data.password, salt);

    return api.post("/new-password", {
      email: data.email,
      password: data.password,
      token: data.token,
    });
  }

  static authenticateEmail(email, token) {
    return api.post("/authenticate-email", {
      email: email,
      token: token,
    });
  }
}
