
import styled from "styled-components";
import bg from "../../assets/img/bg.png"

export const Main = styled.div`
display: flex;
justify-content: flex-end;
min-height: 80vh;
background-image:url(${bg});
background-size: cover;
background-position: 60% 20%;
background-repeat: no-repeat;

.all-contents{
    display: grid;
    width: 50vw;
    height: 60vh;
    text-align: center;
    margin-right: 10%;
}
h1{

color:#77051B;
margin-top: 10%;

}
img{

     width:40%;

}   
`
export const Container = styled.main`
display: grid;
padding: 10%;
background-color:rgba(167,162,169,0.5);
border-radius: 5px;
div{
    display: grid;
    justify-items: center;
}
`