import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DetailsData from '../DetailsData';



 const DenseAppBar=(props)=> {
  return (
    
      <AppBar position="static"  >
        <Toolbar style={{Height : '10'}} >
          <DetailsData/>
          <Typography >
           <span>{props.name}</span>       
           <spam>{props.date}</spam>
          </Typography>
        </Toolbar>
      </AppBar>
 
  );
}
export default DenseAppBar