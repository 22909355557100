import React from "react";
import ReactDOM from "react-dom";
import Routes from "./config/routes";
import WebFont from "webfontloader";
import { AuthProvider } from "./contexts/auth";
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './config/theme';

WebFont.load({
  google: {
    families: ["Montserrat", "Questrial"],
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AuthProvider>
      <Routes />
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

