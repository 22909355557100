
import { Button, TextField } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import NewFooter from "../../components/NewFooter"
import NewHeader from "../../components/NewHeader"
import { goToUser } from "../../config/cordination"
import { WrapperMain, WrapperNewData, ContainerInputs, ContainerMap } from "./styles"
import { useForm } from "antd/lib/form/Form"


const NewData = () => {
    const history = useHistory()
    const [form, onChange, clear] = useForm({
        name: "",
        date: "",
        birthTime: "",
        searchLocal: "",
        timeZoneName: "",
        coordinatesLat: "",
        coordinatesLng: "",
    })//passar essas informações para a requisição do axios create

    const onSubmitForm = (event) => {
        event.preventDefault()
        clear()
    }
    return (
        <div>
            <NewHeader />
            <WrapperMain>
                <WrapperNewData>

                    <form onSubmit={onSubmitForm}>
                        <h3>Cadastre uma nova data</h3>
                        <ContainerInputs className="inputs-container">

                            {/* não estou conseguindo digitar nos inputs */}
                            <TextField
                                className="input"
                                required
                                id="filled-required"
                                placeholder="NOME"
                                variant="filled"
                                fullWidth
                                name={"name"}
                                type="text"

                            /><TextField
                                className="input"
                                required
                                id="filled-required"
                                placeholder="DD/MM/AAA"
                                variant="filled"
                                fullWidth
                                name={"date"}
                                value={form.date}
                                onChange={onChange}
                                type="date"

                            /><TextField
                                className="input"
                                required
                                id="filled-required"
                                placeholder="00:00"
                                variant="filled"
                                fullWidth
                                name={"birthTime"}
                                value={form.birthTime}
                                onChange={onChange}
                                type="time"

                            /><TextField
                                className="input"
                                required
                                id="filled-required"
                                placeholder="Cidade de Nascimento"
                                variant="filled"
                                fullWidth
                                name={"searchLocal"}
                                value={form.searchLocal}
                                onChange={onChange}
                                type="text"

                            /><TextField
                                className="input"
                                required
                                id="filled-required"
                                placeholder="Fuso-Horário"
                                variant="filled"
                                fullWidth
                                name={"timeZoneName"}
                                value={form.timeZoneName}
                                onChange={onChange}
                                type="text"

                            /><TextField
                                className="input"
                                required
                                id="filled-required"
                                placeholder="Latitude"
                                variant="filled"
                                fullWidth
                                name={"coordinatesLat"}
                                value={form.coordinatesLat}
                                onChange={onChange}
                                type="text"

                            /><TextField
                                className="input"
                                required
                                id="filled-required"
                                placeholder="Longitude"
                                variant="filled"
                                fullWidth
                                name={"coordinatesLng"}
                                value={form.coordinatesLng}
                                onChange={onChange}
                                type="text"
                            />
                        </ContainerInputs>

                        <ContainerMap >
                            <div>
                                <Button onClick={() => goToUser(history)} variant="contained" color="secondary" >cancelar</Button>
                                <Button
                                    type={"submit"}
                                    variant="contained"
                                    color="primary"
                                >
                                    Cadastrar</Button>
                            </div>
                        </ContainerMap>
                    </form>
                </WrapperNewData>

            </WrapperMain>
            <NewFooter />
        </div >
    )
}
export default NewData